import { createPlugin, createRoutableExtension, createComponentExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const kosliPlugin = createPlugin({
  id: 'kosli',
  routes: {
    root: rootRouteRef,
  },
});

export const KosliPage = kosliPlugin.provide(
  createRoutableExtension({
    name: 'KosliPage',
    component: () =>
      import('./components/KosliPage/KosliPage').then(m => m.KosliPage),
    mountPoint: rootRouteRef,
  }),
);

export const KosliCard = kosliPlugin.provide(
    createComponentExtension({
        name: 'KosliCard',
        component: {
            lazy: () => import ('./components/KosliCard').then(m => m.KosliCard)
        }
    }))
