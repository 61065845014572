/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {PropsWithChildren} from 'react';
import {Link, makeStyles} from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import GroupsIcon from '@material-ui/icons/Group';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {NavLink} from 'react-router-dom';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarSpace,
    useSidebarOpenState,
} from '@backstage/core-components';
import {MyGroupsSidebarItem} from '@backstage/plugin-org';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {RequirePermission} from "@backstage/plugin-permission-react";
import {catalogEntityCreatePermission} from "@backstage/plugin-catalog-common/alpha";
import {FeatureFlagged} from '@backstage/core-app-api'

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link
                component={NavLink}
                to="/"
                underline="none"
                className={classes.link}
                aria-label="Home"
            >
                {isOpen ? <LogoFull/> : <LogoIcon/>}
            </Link>
        </div>
    );
};

export const Root = ({children}: PropsWithChildren<{}>) => (
    <SidebarPage>
        <Sidebar>
            <SidebarLogo/>
            <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                <SidebarSearchModal/>
            </SidebarGroup>
            <SidebarDivider/>
            <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                {/* Global nav, not org-specific */}
                <FeatureFlagged with="enable-home-page">
                    <SidebarItem icon={CategoryIcon} to="/" text="Home"/>
                </FeatureFlagged>
                <MyGroupsSidebarItem icon={GroupsIcon} pluralTitle="My Teams" singularTitle="My team"/>
                <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog"/>
                <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
                <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
                <SidebarItem icon={SearchIcon} to="search" text="Full Search"/>
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <SidebarItem icon={CreateComponentIcon} to="create" text="Create..."/>
                </RequirePermission>
                {/* End global nav */}
                <SidebarDivider/>
            </SidebarGroup>
            <SidebarSpace/>
            <SidebarDivider/>
            <SidebarGroup
                label="Settings"
                icon={<UserSettingsSignInAvatar/>}
                to="/settings"
            >
                <SidebarSettings/>
            </SidebarGroup>
        </Sidebar>
        {children}
    </SidebarPage>
);
