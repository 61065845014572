import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from '@backstage/integration-react';
import {AnyApiFactory, configApiRef, createApiFactory,} from '@backstage/core-plugin-api';
import "./feature-flags/home";

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi)
    }),
    ScmAuth.createDefaultApiFactory()
];
